// main-program start

.main-program {
	@include py(85px);
	@include max1199 {
		@include py(55px); }
	@include max767 {
		padding-bottom: 10px; } }

.film-about {
	max-width: 715px; }

.schedule-timing {
	@extend .d-flex;
	@extend .flex-wrap;
	margin-bottom: -14px; }

.film-box {
	@extend .d-block;
	min-height: 60px;
	@include px(10px);
	@include py(10px);
	background: $white;
	border: 2px solid $card-grey;
	@include r(4px);
	margin-bottom: 14px;
	@include tm;
	@include max575 {
		@include px(10%); }
	@include h {
		text-decoration: none;
		cursor: pointer;
		border-color: $danger;
		@include tm; }
	time {
		@include robotocondenced;
		@include fz(45);
		@include fw(400);
		color: $text-main;
		margin-bottom: 8px;
		@include max575 {
			@include fz(20); } }
	.px30 {
		@include min1200 {
			@include px(20px); } }
	span {
		@include fz(25);
		@include robotocondenced;
		@include fw(400);
		color: $text-details;
		line-height: 1.2;
		@include ttu;
		@include max575 {
			@include fz(14); } } }

.order-btn {
	.btn {
		min-height: 56px;
		@include py(10px);
		@extend .d-flex;
		@extend .align-items-center;
		@extend .justify-content-center;
		@include max767 {
			@include py(23px); } }
	i {
		@include fz(40); } }

// main-program end

