/* main-section start */

.main-section {
	background-color: $primary; }

.main-slider {
	position: relative;
	.owl-prev,
	.owl-next {
		@extend .d-flex;
		@extend .align-items-center;
		@extend .justify-content-center;
		top: 0;
		bottom: 0;
		position: absolute;
		width: 160px;
		@include max991 {
			width: 60px; }
		@include h {
			.fa {
				background-color: $white;
				color: $danger;
				cursor: pointer;
				@include tm; } }
		&.disabled {
			@include h {
				.fa {
					background-color: transparent;
					color: $white;
					cursor: default;
					@include tm; } } }
		.fa {
			@extend .d-flex;
			@extend .align-items-center;
			@extend .justify-content-center;
			z-index: 20;
			width: 50px;
			height: 50px;
			@include r(50%);
			background: transparent;
			border: 2px solid $white;
			color: $white;
			@include fw(700);
			@include tm;
			@include max991 {
				width: 40px;
				height: 40px; } } }
	.owl-prev {
		left: 0; }
	.owl-next {
		right: 0; } }


.main-slide {
	position: relative;
	min-height: 850px;
	@include py(80px);
	padding-top: 40px;
	z-index: 0;
	background-color: $primary;
	@extend .d-flex;
	@extend .flex-column;
	@extend .align-items-center;
	@extend .justify-content-center;
	@extend .text-center;
	@include max1365 {
		min-height: 700px; }
	@include max991 {
		min-height: 800px;
		padding-bottom: 80px; }
	@include max575 {
		min-height: 750px;
		@include py(50px); }
	@include maxh850 {
		min-height: calc(100vh - 67px);
		@include py(80px); }
	.container {
		@include px(160px);
		@include max991 {
			@include px(60px); }
		@include max767 {
			@include px(15px); } }
	.slide-title {
		color: $white; }
	.slide-bg {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		overflow: hidden;
		@include a {
			@include ct;
			@include abs0;
			background-color: rgba(22, 45, 80, 0.3); }

		>img {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			@include tf(translate(-50%, -50%));
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto; } }
	.container {
		position: relative;
		z-index: 3; }
	.slide-film-time,
	.slide-place,
	p {
		color: $white; }
	.slide-place {
		@extend .d-block;
		@extend .text-center;
		@include fz(20);
		@include fw(300);
		@include ttu;
		@include max767 {
			@include fz(18); }
		@include max575 {
			@include fz(16); } }
	.mb25 {
		@include max767 {
			margin-bottom: 30px; } } }



.calendar-scedule {
	height: 96px;
	width: 96px;
	@include px(10px);
	@include py(10px);
	@extend .d-inline-block;
	@extend .d-lg-flex;
	@extend .flex-column;
	@extend .align-items-center;
	@extend .justify-content-center;
	@extend .m-auto;
	@include r(4px);
	background-color: $red;
	@include max991 {
		width: auto;
		height: auto;
		@include px(25px);
		@include py(10px); }
	@include max767 {
		@include py(15px);
		@include px(35px); }
	span {
		color: $white;
		@include robotocondenced;
		line-height: 1.2; }
	.date {
		@include fz(24);
		@include fw(400); }
	.month {
		@include fz(30);
		@include fw(700);
		@include ttu; }
	.date,
	.month {
		@include max991 {
			@include fz(30); } } }

.slide-film-time {
	@include fz(30);
	@include font-main;
	@include fw(400);
	@include max991 {
		@include fz(28); }
	@include max767 {
		@include fz(25); }
	@include max575 {
		@include fz(22); } }

.main-order {
	@include max767 {
		max-width: 270px;
		@include mx(auto); }
	.btn {
		@include mx(4px);
		@include max767 {
			display: block;
			width: 100%;
			margin-bottom: 15px; } } }

/* main-section end */
