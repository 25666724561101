/* navbar start */
.navbar {
	padding: 0;
	margin-bottom: 0;
	position: relative;
	@extend .flex-wrap; }


.navbar-nav {
	@include p(0);
	@extend .align-items-stretch;
	@include max1199 {
		padding-left: 20px; }
	@include max991 {
		padding-top: 15px;
		padding-left: 0px; }
	.nav-item {
		@extend .align-items-stretch;
		@include max991 {
			text-align: center; }
		&.active {
			.nav-link {
				color: $danger;
				@include a {
					left: 0;
					right: 0;
					@include tm; } } }
		.nav-link {
			@extend .d-flex;
			@extend .h-100;
			@extend .align-items-center;
			@extend .justify-content-center;
			white-space: nowrap;
			position: relative;
			z-index: 0;
			@include a {
				@include ct;
				position: absolute;
				z-index: 1;
				height: 3px;
				bottom: 0;
				left: 50%;
				right: 50%;
				background: $danger;
				@include tm;
				@include max991 {
					display: none; } }
			@include h {
				color: $danger;
				@include a {
					left: 0;
					right: 0;
					@include tm; } }

			@include min768 {
				@include px(10px);
				@include py(12px); }
			@include min1600 {
				@include px(18px); }
			@include max1365 {
				@include fz(14); }
			@include max1199 {
				@include fz(12);
				@include px(8px); }
			@include max991 {
				@include fz(16); }
			&.active {
				color: $primary; } } } }

.nav-item {
	@include max991 {
		border-bottom: 1px solid $middle-grey;
		@include l {
			.nav-link {
				border-bottom: none; } } } }

.nav-link {
	@extend .d-block;
	@extend .d-md-inline-block;
	@extend .d-lg-block;
	@include fz(16);
	@include robotocondenced;
	@include fw(400);
	@include ttu;
	@include px(12px);
	@include py(5px);
	line-height: 1.2;
	color: $text-nav;
	@include h {
		color: $primary;
		cursor: pointer; }
	@include max1199 {
		@include fz(14); }
	@include max991 {
		text-align: center;
		@include fz(16);
		@include py(12px);
		@include tm;
		@include h {
			color: $danger;
			@include tm; } } }

.navbar-toggler {
	display: block;
	padding: 10px 0;
	border: none;
	border-radius: 0px;
	outline: none;
	cursor: pointer;
	margin-top: 14px;
	margin-right: 15px;
	@include max575 {
		margin-right: 0;
		margin-top: 0; }
	@include fc {
		outline: none;
		cursor: pointer; }
	.icon-bar {
		display: block;
		height: 5px;
		width: 40px;
		margin: 7px auto;
		background-color: $primary;
		@include tm;
		@include max575 {
			height: 3px;
			width: 30px;
			margin: 5px auto; } }
	&[aria-expanded="true"] {
		.icon-bar1 {
			margin-top: 4px;
			@include tf(rotate(135deg)); }
		.icon-bar2 {
			opacity: 0; }
		.icon-bar3 {
			margin-top: -24px;
			@include tf(rotate(-135deg));
			@include max575 {
				margin-top: -15px; } } } }

/* navbar end */




