$white: 	#fff;
$grey: 		#f7f7f7;
$red: 		#ff3333;
$dirty_grey: #e9eef2;
$placeholder: #b8b8b8;
$footer-bg: #f9f9f9;
$copy-color: #868686;
$middle-grey: #ebebeb;
$dark-grey: #ababab;
$card-grey: #e5e5e5;
$yellow: 	#ffd564;
$dark:		#282626;
$hr-grey:	#dbdbdb;

$primary: 	#162d50;
$danger: 	#ff0065;
$dark_primary: #005578;
$info: 		#009fe3;
$dark_info: #008ab7;

$text-nav: #151515;
$text-main: #171616;
$text-grey: #282828;
$text-details: #737373;
$text_link: #0075bf;

$header-bg: $white;

$container-width: 	1230px;
$container-small:	740px;
$content-user: 		1030px;
$space-user: 		14px;
$space-tablet-user:	25px;
$space-mob-user:	15px;
$space-xs-user:		5px;
$space-user-large: 			20px;
$space-tablet-user-large:	25px;
$space-mob-user-large:		15px;

// Animations Time
$transition-time: 0.3s;







