@font-face {
  font-family: "Impact";
  src: url("../fonts/Impact.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/*user styles*/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h2.large {
  font-family: "Impact";
  font-size: 100px;
  font-weight: 400;
  margin: 0;
}

h3 {
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

h4 {
  font-size: 26px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  margin: 0;
}

p {
  font-size: 18px;
  margin-bottom: 14px;
  font-weight: 400;
  font-family: "Robotocondensed";
  line-height: 1.3;
  margin-top: 0;
}

.slider-section {
  position: relative;
  background-color: #1074bd;
  background-image: url(../img/slider/main-slider-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media print, screen and (max-width: 991px) {
  .slider-section {
    position: relative;
    background-color: #fff;
    background-image: none;
    margin: 15px 0;
  }
}

.slider-section .swiper-container {
  padding-top: 35px;
  padding-bottom: 35px;
  position: relative;
}

@media print, screen and (max-width: 1199px) {
  .slider-section .swiper-container {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.slider-section .slide-item {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 40px;
  padding-bottom: 60px;
  text-align: left;
}

@media print, screen and (max-width: 1365px) {
  .slider-section .slide-item {
    padding-right: 45px;
    padding-left: 45px;
  }
}

.film-description,
.film-image {
  display: inline-block;
  vertical-align: middle;
}

.film-description {
  background-color: #fff;
  background-image: url(../img/slider/film-description.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 5;
  margin-right: -60px;
  width: 515px;
  flex: 515px 0 0;
  min-height: 370px;
  padding: 26px 56px 26px;
  background-color: #fff;
  flex-direction: column;
  text-align: center;
}

@media print, screen and (max-width: 1199px) {
  .film-description {
    padding-right: 20px;
    padding-left: 20px;
    min-height: 340px;
    width: 485px;
    flex: 485px 0 0;
  }
}

@media print, screen and (max-width: 991px) {
  .film-description {
    display: block;
    margin-right: auto;
    margin-left: auto;
    /*margin-bottom: 20px;*/
    width: 100%;
    /*max-width: 515px;*/
    height: auto;
    min-height: 1px;
    padding: 30px 20px;
  }
}

.film-image {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  display: block;
  width: 745px;
  padding: 0;
  overflow: hidden;
  border: 9px solid #fff;
  box-sizing: border-box;
  margin-left: auto;
}

.film-image:before {
  content: "";
  display: block;
  padding-top: 58%;
}

.banner-image-seule {
  max-width: 1200px;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border: 0;
  margin-right: auto;
  margin-left: auto;
  border: 9px solid #fff;
}

@media print, screen and (max-width: 1199px) {
  .film-image {
    width: calc(100% - 515px + 40px);
  }
}

@media print, screen and (max-width: 1365px) {
  .film-image {
    right: 45px;
  }
}

@media print, screen and (max-width: 991px) {
  .film-image {
    position: relative;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    transform: none;
    top: auto;
    left: auto;
    right: auto;
    border-width: 4px;
  }
  .swiper-pagination-bullet {
    background-color: #71758a;
  }
  .banner-time {
    font-size: 14px;
  }
  .slider-section .slide-item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .film-image {
    border-width: 0px;
    margin-bottom: 5px;
  }
}

.film-image img {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  border: 0;
  background-color: #fff;
}

.film-description h4 {
  font-family: "Roboto", sans-serif;
  color: #162d50;
  font-weight: 700;
}

.film-description h2.large {
  font-family: "Impact";
  font-size: 80px;
  color: #162d50;
  text-transform: uppercase;
}

@media print, screen and (max-width: 1365px) {
  .film-description h2.large {
    font-size: 80px;
  }
}

@media print, screen and (max-width: 1199px) {
  .film-description h2.large {
    font-size: 66px;
  }
}

@media print, screen and (max-width: 767px) {
  .film-description h2.large {
    font-size: 50px;
  }
}

@media print, screen and (max-width: 575px) {
  .film-description h2.large {
    font-size: 30px;
  }
  .film-description h4 {
    font-size: 17px;
  }
  .film-description p {
    font-size: 14px;
  }
  .swiper-button-next .swiper-button-prev {
    display: none;
  }
  .banner-time {
    font-size: 14px;
  }
}

.film-description p {
  font-family: "Roboto", sans-serif;
  color: #242424;
  font-weight: 400;
  padding-top: 25px;
}

.banner-time {
  color: #162d50;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  margin-bottom: 0;
}

.film-description h3 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 25px;
  color: #ff0065;
  font-weight: 700;
}

@media print, screen and (max-width: 767px) {
  .film-description h3 {
    font-size: 20px;
  }
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb30 {
  margin-bottom: 30px;
}

@media print, screen and (max-width: 767px) {
  .slider-section .slide-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .slider-section .swiper-button-next {
    display: none;
  }
  .slider-section .swiper-button-prev {
    display: none;
  }
}

/*arrows*/
.slider-section .swiper-button-next {
  right: 15px;
  left: auto;
  color: white;
}

.slider-section .swiper-button-prev {
  left: 15px;
  right: auto;
  color: white;
}

/*navigation*/
@media print, screen and (min-width: 992px) {
  .swiper-pagination-bullet {
    background-color: white;
  }
  .film-description h2.large {
    font-size: 60px;
  }
}
