// schedule-slider start

.schedule-slider {
	@include px(30px);
	position: relative;
	.slide-name {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-center;
		word-break: break-word;
		background: $white;
		border: 1px solid $middle-grey;
		border-top: none;
		border-bottom-width: 2px;
		@include px(15px);
		@include py(13px);
		text-align: center;
		@include fz(18);
		@include robotocondenced;
		@include fw(400);
		color: $text-main;
		@include tm;
		@include max767 {
			@include py(22px);
			@include fz(20); }
		@include max575 {
			@include fz(18);
			line-height: 1.2;
			@include py(5px);
			min-height: 80px; }
		@include h {
			background-color: lighten($danger, 2%);
			@include fw(700);
			color: $white;
			@include tm;
			cursor: pointer;
			text-decoration: none; }
		&.active {
			background-color: $danger;
			@include fw(700);
			color: $white; } }
	.owl-prev,
	.owl-next {
		@extend .d-flex;
		@extend .align-items-center;
		@extend .justify-content-center;
		top: 0;
		bottom: 0;
		position: absolute;
		width: 30px;
		background: $middle-grey;
		@include max767 {
			width: 50px; }
		@include max575 {
			width: 40px; }
		@include h {
			.fa {
				color: $danger;
				cursor: pointer;
				@include tm; } }

		.fa {
			color: $dark-grey;
			@include fw(700);
			@include tm; } }
	.owl-prev {
		left: 0; }
	.owl-next {
		right: 0; } }


.mobile-slider {
	@include max991 {
		padding-top: 40px; }
	@include max767 {
		padding-top: 20px; } }


// schedule-slider end

