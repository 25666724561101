/* events-section start */

.events-section {
	.grey-box {
		@extend .h-100; } }

.grey-box {
	@include p(30px);
	@include r(4px);
	background-color: $grey;
	@include max767 {
		@include p(25px); }
	@include max575 {
		@include p(15px); } }


.danger-label {
	@extend .d-block;
	@extend .text-center;
	@include ttu;
	background-color: $danger;
	color: $white;
	@include fz(18);
	@include fw(500);
	justify-content: center; }


.danger-label,
.dark-label {
	@include px(30px);
	@include py(5px);
	@include font-main;
	min-height: 60px;
	@extend .d-flex;
	align-items: center;
	@include max991 {
		@include px(20px); }
	i, img {
		margin-right: 12px; }
	time {
		> span, img {
			@include im; } }
	img {
		max-width: 25px;
		@include max767 {
			max-width: 20px; } } }

.dark-label {
	background-color: $dark;
	@include font-main;
	@include fz(28);
	color: $white;
	@include fw(400);
	@include max1199 {
		@include fz(24); }
	@include max991 {
		@include fz(16); }
	@include max575 {
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		@include py(14px); }
	.yellow-text {
		@include fw(700); }
	time {
		@include max575 {
			margin-bottom: 10px; } } }

.events-preview {
	flex: 0 0 405px;
	@include max1199 {
		flex: 0 0 360px; }
	@include max991 {
		flex: 0 0 300px; }
	@include max767 {
		flex: 0 0 100%; }
	.grey-box {
		@include max991 {
			padding-right: 0;
			width: 100%; }
		@include max767 {
			padding-right: 15px;
			padding-bottom: 30px;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0; } } }

.event-description {
	@include min992 {
		margin-left: 20px; }
	.grey-box {
		@include max767 {
			border-top-right-radius: 0;
			border-top-left-radius: 0; } }
	.film-about {
		.mb55 {
			@include l {
				margin-bottom: 20px;
				@include max991 {
					margin-bottom: 0; } } } } }

/* events-section end */
