/* footer start */
.footer {
	background-color: transparent;
	padding-top: 0;
	@include py(60px);
	background-color: $footer-bg;
	@include max575 {
		@include py(40px); } }

.social {
	color: $primary;
	@include tm;
	@include h {
		color: $primary;
		cursor: pointer;
		@include tm; }
	&.mb20 {
		@include max767 {
			margin-bottom: 35px; } }
	span {
		@include fz(22);
		@include font-main;
		@include fw(400);
		@include ttu;
		@include max767 {
			@include fz(26);
			line-height: 1.2; }
		@include max575 {
			@include fz(24); } }
	i {
		@include fz(40);
		@include max767 {
			@include fz(70); }
		@include max575 {
			@include fz(50); } } }

.copy {
	@include fz(16);
	@include fw(400);
	@include font-main;
	line-height: 1.2;
	color: $copy-color;
	@include tm;
	@include h {
		@include tm;
		color: $dark-grey;
		cursor: pointer; }
	@include max767 {
		@include fz(18); } }

/* footer end */
