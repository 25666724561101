// form start

.subscribe-form {
  @include max767 {
    @include mx(auto); }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto; }
  li {
    color: $white;
    display: inline-block;
    position: relative;
    @include mx(20px);
    @include max767 {
      margin-bottom: 5px; }
    @include h {
      label {
        color: $white; } }
    label {
      display: block;
      position: relative;
      @include font-main;
      @include fw(400);
      @include fz(22);
      padding: 0px 0px 15px 40px;
      margin: auto;
      min-height: 36px;
      z-index: 9;
      cursor: pointer;
      @include tm;
      @include max575 {
        @include fz(18); }
      @include h {
        cursor: pointer; } }
    .check {
      display: block;
      position: absolute;
      border: 2px solid $white;
      border-radius: 50%;
      height: 22px;
      width: 22px;
      top: 5px;
      left: 0px;
      z-index: 5;
      transition: border 0.25s linear;
      -webkit-transition: border 0.25s linear;
      @include h {
        cursor: pointer; }
      @include b {
        display: block;
        position: absolute;
        content: '';
        border-radius: 50%;
        height: 12px;
        width: 12px;
        top: 3px;
        left: 3px;
        margin: auto;
        transition: background 0.25s linear;
        -webkit-transition: background 0.25s linear; } } }
  input[type=radio] {
    position: absolute;
    visibility: hidden; } }

input[type=radio]:checked ~ .check:before {
  background: $white; }


.mail-wrap {
  max-width: 780px;
  @include mx(auto);
  @extend .d-md-flex;
  @extend .justify-content-center;
  @extend .align-items-stretch;
  @include max767 {
    max-width: 520px; }
  .btn {
    min-width: 195px;
    margin-left: 4px;
    @extend .d-block;
    @extend .d-md-inline-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @include max767 {
      display: block;
      width: 100%;
      margin-left: 0;
      @include py(18px);
      @include fz(26); } }

  .form-control {
    @include max767 {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      @include fz(22); } } }

.form-control {
  display: block;
  height: auto;
  @include r(3px);
  @include robotocondenced;
  @include fz(18);
  @include fw(400);
  line-height: 1.2;
  @include px(35px);
  @include py(18px);
  min-width: 140px;
  @include ph($placeholder);
  color: $text-main;
  @include max767 {
    @include py(24px); }
  @include max575 {
    @include py(20px);
    @include px(25px); }
  @include fc {
    border-color: $danger;
    background-clip: unset; } }

.form-control-small {
  @include py(12px);
  @include px(22px);
  @include font-main; }

textarea.form-control {
  min-height: 120px;
  @include font-main; }

.form-section {
  @include py(50px);
  background-color: $primary; }

.user-form-wrap {
  max-width: 780px;
  @include mx(auto); }

.button-section {
  max-width: 450px;
  @include mx(auto); }

.captcha {
  height: 100px;
  width: 330px;
  @include mx(auto);
  background-color: $grey;
  @include max767 {
    max-width: 330px;
    width: auto; } }

.info-form {
  padding-top: 110px;
  padding-bottom: 90px;
  @include max991 {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include max767 {
    @include py(60px); }
  @include max575 {
    @include py(50px); }
  .form-title {
    * {
      color: $white; } } }

.search {
  display: block;
  max-width: 700px;
  @include mx(auto); }

.search-form-wrap {
  position: relative;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
  .form-control {
    min-height: 70px;
    font-style: italic;
    &::-webkit-input-placeholder {
      font-style: italic; }
    &:-moz-placeholder {
      font-style: italic; }
    &::-moz-placeholder {
      font-style: italic; }
    &:-ms-input-placeholder {
      font-style: italic; } } }

.search-button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 80px;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  color: $grey;
  @include tm;
  @include h {
    background-color: $danger;
    color: $white;
    cursor: pointer;
    @include tm; } }


// form end
