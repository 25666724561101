body {
	position: relative;
	margin-right: auto;
	margin-left: auto;
	@include font-main;
	@include fz(14);
	background-color: $body-bg;
	color: $text-main; }

html,
body {
	min-height: 100vh;
	position: relative; }

.ttu {
	@include ttu; }

.page-title {
	@include py(50px);
	background: $primary;
	@extend .text-center;
	@include max991 {
		@include py(40px); }
	* {
		color: $white; } }

@include selection(lighten($danger, 45%));

hr {
	margin: 0;
	padding: 0;
	height: 1px;
	width: 100%;
	background-color: $hr-grey; }

.yellow-text {
	color: $yellow; }

.page {
	min-height: calc(100vh - 93px - 247px);
	@include min1640 {
		min-height: calc(100vh - 109px - 247px); }
	@include min1600 {
		min-height: calc(100vh - 103px - 247px); }
	@include min992 {
		min-height: calc(100vh - 69px - 247px); }
	@include max1199 {
		min-height: calc(100vh - 75px - 247px); }
	@include max991 {
		min-height: calc(100vh - 112px - 247px); }
	@include max767 {
		min-height: calc(100vh - 137px - 288px); }
	@include max575 {
		min-height: calc(100vh - 80px - 247px); } }

.inner-section {
	@include py(50px); }

.large-inner-section {
	@include py(110px);
	@include max991 {
		@include py(80px); }
	@include max575 {
		@include py(50px); }
	.btn {
		@include py(25px); } }

.inner-button-section {
	@include py(60px);
	@include max575 {
		@include py(50px); }
	.btn {
		@include py(25px); } }
