.map-wrap {
	position: relative;
	z-index: 0;
	height: 760px;
	background-color: $primary;
	background-image: url(../img/map.jpg);
	background-size: cover;
	@include max991 {
		height: 600px; }
	@include max767 {
		height: 500px; } }

.search-map-content {
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	@extend .d-flex;
	@extend .flex-column;
	@extend .align-items-center;
	@extend .justify-content-center; }
