.typo-list {
	padding: 0;
	margin: 0;
	display: block;
	list-style: none;
	padding-top: 8px;
	li {
		display: block;
		position: relative;
		padding-left: 14px;
		margin-bottom: 20px;
		@include b {
			@include ct;
			position: absolute;
			top: 10px;
			left: 0;
			width: 8px;
			height: 1px;
			background-color: $text-main; }
		@include max991 {
			@include b {
				top: 14px; } } }
	p {
		@include font-main; } }
