/*header start */
.header {
	background-color: $header-bg;
	@include px(25px);
	@include max1600 {
		@include px(0); }
	@include max767 {
		padding-top: 5px;
		padding-bottom: 20px;
		@include px(20px); }
	@include max575 {
		@include px(0);
		padding-bottom: 5px; } }

.logo {
	@extend .d-inline-flex;
	@extend .mr-auto;
	max-width: 500px;
	@include max1700 {
		padding-right: 50px; }
	@include max1600 {
		max-width: 450px; }
	@include max1365 {
		max-width: 400px;
		padding-right: 30px; }
	@include max1199 {
		max-width: 250px;
		padding-right: 0; }
	@include max991 {
		width: 75%;
		max-width: 500px; }
	.logo-left,
	.logo-right {
		display: block;
		max-width: 50%;
		padding-top: 25px;
		padding-bottom: 20px;
		@include max1600 {
			@include py(20px); }
		@include h {
			cursor: pointer;
			img.d-none {
				display: block!important; }
			img.active {
				display: none!important; } }
		&.active {
			@include h {
				img.d-none {
					display: none!important; }
				img.active {
					display: block!important; } } } }
	.logo-left {
		position: relative;
		z-index: 0;
		@include b {
			@include ct;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 1px;
			background-color: $card-grey;
			z-index: 1; }
		img {
			margin-right: auto; } }
	.logo-right {
		img {
			display: block;
			margin-left: auto; }
		@include h {
			img.d-none {
				margin-left: auto; }
			img.active {
				margin-left: auto; } } }
	img {
		max-width: 80%;
		@include max1600 {
			max-width: 90%; } } }

/* header end */
