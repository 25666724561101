// card start

.card {
	display: block;
	@include p(30px);
	background-color: $grey;
	border: none;
	@include r(4px);
	@include max767 {
		@include p(25px); }
	@include max575 {
		@include p(15px); }
	&.mb20 {
		@include max767 {
			margin-bottom: 30px; } } }

.order-box {
	width: 345px;
	float: left;
	@include max991 {
		width: 300px; }
	@include max767 {
		width: 100%;
		float: none;
		max-width: 650px;
		@include mx(auto);
		margin-bottom: 60px; }
	@include max575 {
		margin-bottom: 40px; }
	.card-image {
		margin-bottom: 10px;
		&.mb5 {
			@include max767 {
				margin-bottom: 20px; } } } }

.card-image {
	position: relative;
	z-index: 0;
	height: 0;
	padding-top: 131%;
	background: $primary;
	overflow: hidden;
	img {
		position: absolute;
		display: block;
		z-index: 3;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		@include tf(translate(-50%, -50%)); } }

.program-info {
	@include fz(16);
	@include fw(400);
	line-height: 1.2;
	@include font-main;
	color: $text-grey;
	@include ttu;
	@include max767 {
		@include fz(24); }
	@include max575 {
		@include fz(18); } }

.info-box {
	float: left;
	width: calc(100% - 345px);
	padding-left: 30px;
	padding-top: 10px;
	@include max991 {
		width: calc(100% - 300px); }
	@include max767 {
		width: 100%;
		padding-left: 0;
		float: none; } }

.card-section {
	@extend .d-flex;
	@extend .flex-wrap;
	@extend .flex-md-nowrap;
	@extend .align-items-stretch;
	.order-box,
	.info-box {
		@extend .h-100; }
	&.mb35 {
		@include l {
			margin-bottom: 0; } } }

.events-card-wrap {
	&.card-image {
		padding-top: 110%;
		@include max991 {
			padding-top: 130%; }
		@include max767 {
			max-width: 650px;
			@include mx(auto); } } }


// card end
