
.price-item {
	@extend .d-flex;
	@extend .flex-column;
	@extend .align-items-center;
	@extend .justify-content-center;
	background: $white;
	border: 1px solid $card-grey;
	@include r(4px);
	@include py(15px);
	@include px(20px);
	@include max991 {
		margin-bottom: 20px; } }

.tarif {
	@include font-main;
	@include fz(26);
	line-height: 1.2;
	color: $text-details;
	@include max1199 {
		@include fz(22); } }

.price {
	@include fz(42); }

.post {
	p {
		@include font-main; } }

.bordered-box {
	@extend .d-flex;
	@extend .flex-column;
	background: $white;
	border: 1px solid $card-grey;
	@include r(4px);
	@include py(25px);
	@include px(35px);
	@include max767 {
		@include px(25px); }
	&.bordered-rectangle {
		@include r(0); }
	h6, p {
		@include font-main; }
	p {
		line-height: 1.6; }
	&.py40 {
		@include py(40px); } }

.grey-bordered {
	@extend .d-flex;
	@extend .flex-column;
	background: $grey;
	border: 1px solid $card-grey;
	@include r(4px);
	@include py(25px);
	@include px(35px);
	@include max767 {
		@include px(25px); } }
