@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Black.woff");
	font-weight: 900;
	font-style: normal; }

@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Bold.woff");
	font-weight: 700;
	font-style: normal; }

@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Medium.woff");
	font-weight: 500;
	font-style: normal; }

@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Regular.woff");
	font-weight: 400;
	font-style: normal; }

@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Light.woff");
	font-weight: 300;
	font-style: normal; }

@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Thin.woff");
	font-weight: 200;
	font-style: normal; }

@font-face {
	font-family: "Impact";
	src: url("../fonts/Impact.woff");
	font-weight: 400;
	font-style: normal; }

@font-face {
	font-family: "Robotocondensed";
	src: url("../fonts/RobotoCondensed-Bold.woff");
	font-weight: 700;
	font-style: normal; }

@font-face {
	font-family: "Robotocondensed";
	src: url("../fonts/RobotoCondensed-Regular.woff");
	font-weight: 400;
	font-style: normal; }


