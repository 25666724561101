/* heading styles start */
@include hd {
	@include font-main;
	@include m(0);
	color: $text-main; }

h1 {
	@include impact;
	@include fz(100);
	@include fw(400);
	line-height: 1.2;
	@include ttu;
	@include max1365 {
		@include fz(80); }
	@include max1199 {
		@include fz(66); }
	@include max767 {
		@include fz(50); }
	@include max575 {
		@include fz(40); }
	&.small {
		display: block;
		@include fz(30);
		@include fw(700);
		@include font-main;
		@include max991 {
			@include fz(28); }
		@include max767 {
			@include fz(46);
			margin-bottom: 35px; }
		@include max575 {
			@include fz(28); } } }

h2 {
	@include fz(36);
	@include fw(400);
	@include ttu;
	text-align: center;
	@include max1199 {
		@include fz(34); }
	@include max991 {
		@include fz(32); }
	@include max767 {
		@include fz(28); }
	@include max575 {
		@include fz(24); }
	&.large {
		@include impact;
		@include fz(100);
		@include fw(400);
		@include max1365 {
			@include fz(80); }
		@include max1199 {
			@include fz(70); }
		@include max767 {
			@include fz(50); }
		@include max575 {
			@include fz(40);
			@include fw(400); } }
	&.small {
		display: block;
		@include fz(30);
		@include fw(700);
		@include font-main;
		@include max991 {
			@include fz(28); }
		@include max767 {
			@include fz(46);
			margin-bottom: 35px; }
		@include max575 {
			@include fz(28); } } }
h3 {
	@include fz(28);
	@include fw(700);
	@include ttu;
	@include max991 {
		@include fz(26); }
	@include max767 {
		@include fz(24); } }

h4 {
	@include fz(26);
	@include robotocondenced;
	@include fw(700);
	@include max767 {
		@include fz(30); } }

h5 {
	@include fz(22);
	margin-bottom: 5px;
	@include max991 {
		@include fz(22); }
	&.large {
		@include fz(26);
		@include max991 {
			@include fz(24); } } }

h6 {
	@include fz(18);
	@include robotocondenced;
	@include fw(700);
	margin-bottom: 6px;
	@include max767 {
		@include fz(22);
		margin-bottom: 2px; } }

p {
	@include fz(18);
	margin-bottom: 14px;
	@include fw(400);
	@include robotocondenced;
	line-height: 1.3;
	@include max767 {
		@include fz(20); }
	&.large {
		@include fz(30);
		@include fw(400);
		line-height: 1.2;
		@include max991 {
			@include fz(28); }
		@include max767 {
			@include fz(26); } }
	&.lh1-7 {
		line-height: 1.7;
		@include max767 {
			line-height: 1.5; } } }

.note {
	@include fz(11); }

address {
	p {
		@include m(0); }
	a {
		margin-left: 5px;
		margin-right: 5px; } }

a {
	@include f {
		outline: none!important; } }

/* heading styles end */


/* Font-weight styles start*/
.fw900 {
	font-weight: 900; }

.fw700 {
	font-weight: 700; }

.fw500 {
	font-weight: 500; }

.fw400 {
	font-weight: 400; }

.fw300 {
	font-weight: 300; }

.fw200 {
	font-weight: 200; }

/* Font-weight styles end*/



