body {
  font-family: "Roboto Condensed", sans-serif;
  margin: 0;
}

.clip-wrap {
  display: block;
  background-color: #fff;
}

.clip-wrap img {
  border: none;
  outline: none;
}

.clip-item {
  display: block;
  padding: 0;
  margin: 0;
  font: 0;
}

.clip-item .clip-logo {
  position: absolute;
  display: block;
  z-index: 1;
}

.clip-left {
  width: 62.5%;
  float: left;
}

.clip-left .clip-logo {
  top: 34%;
  right: 6.8%;
}

.clip-right {
  width: 48.5%;
  float: right;
  margin-left: -11%;
}

.clip-right .clip-logo {
  top: 55%;
  left: 5.05%;
}

.clip-css {
  background-color: #001c47;
  -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 80% 100%);
  clip-path: polygon(0 100%, 0 0, 100% 0, 80% 100%);
  background-position: center center;
  background-image: url(../img/accueil/bg-left.jpg);
}

.clip-css-reverse {
  background-color: #005da1;
  -webkit-clip-path: polygon(0 100%, 23% 0, 100% 0, 100% 100%);
  clip-path: polygon(0 100%, 23% 0, 100% 0, 100% 100%);
  background-position: center center;
  background-image: url(../img/accueil/bg-right.jpg);
}

.clip-link {
  display: block;
  position: relative;
  z-index: 0;
  height: 100vh;
  overflow: hidden;
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
}

.mobile-show {
  display: none !important;
}

@media screen and (min-width: 3001px) {
  .clip-right .clip-logo {
    left: 7.05%;
  }
}

@media screen and (max-width: 1199px) {
  .clip-left .clip-logo {
    right: 2px;
    transform: scale(0.8);
  }

  .clip-right .clip-logo {
    left: -40px;
    transform: scale(0.8);
  }
}

@media screen and (max-width: 992px) {
  .clip-left .clip-logo {
    right: -45px;
    transform: scale(0.6);
  }

  .clip-right .clip-logo {
    left: -69px;
    transform: scale(0.6);
  }
}

@media screen and (max-width: 639px) {
  .clip-css {
    /*background-image: url(bg-left.jpg);*/
  }

  .clip-css-reverse {
    /*background-image: url(bg-right.jpg);*/
  }
  .clip-link {
    height: 50vh;
    /*height: 400px;*/
  }

  .clip-left {
    width: 100%;
    float: none;
    /*margin-bottom: 10px;*/
  }

  .clip-right {
    width: 100%;
    float: none;
    margin-left: 0;
  }

  .clip-link {
    -webkit-clip-path: none;
    clip-path: none;
    /*background-position: top center;*/
  }

  .clip-left .clip-logo {
    top: -15px;
    right: 5px;
    transform: scale(0.5);
  }

  .clip-right .clip-logo {
    top: -15px;
    left: auto;
    right: 5px;
    transform: scale(0.5);
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-show {
    display: block !important;
  }
}

@media screen and (max-width: 480px) {
  .clip-left .clip-logo {
    top: -25px;
    transform: scale(0.4);
  }

  .clip-right .clip-logo {
    top: -25px;
    transform: scale(0.4);
  }
}

@media screen and (width: 768px) and (height: 1366px) {
  .clip-link {
    height: 50vh;
  }

  .clip-left {
    width: 100%;
    float: none;
  }

  .clip-right {
    width: 100%;
    float: none;
    margin-left: 0;
  }

  .clip-link {
    -webkit-clip-path: none;
    clip-path: none;
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-show {
    display: block !important;
  }

  .clip-left .clip-logo {
    top: 5px;
    right: 5px;
    transform: scale(0.8);
  }

  .clip-right .clip-logo {
    top: 5px;
    left: auto;
    right: 5px;
    transform: scale(0.8);
  }
}

/*for vertical tablet*/
@media screen and (width: 1024px) and (height: 1366px) {
  .clip-link {
    height: 50vh;
  }

  .clip-left {
    width: 100%;
    float: none;
  }

  .clip-right {
    width: 100%;
    float: none;
    margin-left: 0;
  }

  .clip-link {
    -webkit-clip-path: none;
    clip-path: none;
  }

  .clip-left .clip-logo {
    top: 5px;
    right: 5px;
    transform: scale(0.8);
  }

  .clip-right .clip-logo {
    top: 5px;
    left: auto;
    right: 5px;
    transform: scale(0.8);
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-show {
    display: block !important;
  }
}

/*for vertical tablet*/
@media screen and (width: 768px) and (height: 1024px) {
  .clip-link {
    height: 50vh;
  }

  .clip-left {
    width: 100%;
    float: none;
  }

  .clip-right {
    width: 100%;
    float: none;
    margin-left: 0;
  }

  .clip-link {
    -webkit-clip-path: none;
    clip-path: none;
  }

  .clip-left .clip-logo {
    top: 5px;
    right: 5px;
    transform: scale(0.8);
  }

  .clip-right .clip-logo {
    top: 5px;
    left: auto;
    right: 5px;
    transform: scale(0.8);
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-show {
    display: block !important;
  }
}

/*for IE only styles*/
@media all and (-ms-high-contrast: none) {
  /*IE10*/
  .clip-left {
    width: 62.5%;
    float: left;
  }

  /*IE11*/
  *::-ms-backdrop,
  .clip-left {
    width: 62.5%;
    float: left;
  }

  /*IE10*/
  .clip-right {
    width: 37.5%;
    float: right;
    margin-left: 0;
  }

  /*IE11*/
  *::-ms-backdrop,
  .clip-right {
    width: 37.5%;
    float: right;
    margin-left: 0;
  }

  /*IE10*/
  .clip-left .clip-logo {
    right: 25px;
  }

  /*IE11*/
  *::-ms-backdrop,
  .clip-left .clip-logo {
    right: 25px;
  }

  /*IE10*/
  .clip-right .clip-logo {
    left: 25px;
  }

  /*IE11*/
  *::-ms-backdrop,
  .clip-right .clip-logo {
    left: 25px;
  }

  /*IE10*/
  .clip-css {
    -webkit-clip-path: none;
    clip-path: none;
  }

  /*IE11*/
  *::-ms-backdrop,
  .clip-css {
    -webkit-clip-path: none;
    clip-path: none;
  }

  /*IE10*/
  .clip-css-reverse {
    -webkit-clip-path: none;
    clip-path: none;
  }

  /*IE11*/
  *::-ms-backdrop,
  .clip-css-reverse {
    -webkit-clip-path: none;
    clip-path: none;
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-show {
    display: block !important;
  }

  *::-ms-backdrop,
  .mobile-hide {
    display: none !important;
  }

  *::-ms-backdrop,
  .mobile-show {
    display: block !important;
  }
}

/*for IE only styles*/
@media all and (-ms-high-contrast: none) and (max-width: 1199px) {
  /*IE10*/
  .clip-left .clip-logo {
    transform: scale(0.5);
    right: -25px;
  }

  /*IE11*/
  *::-ms-backdrop,
  .clip-left .clip-logo {
    transform: scale(0.5);
    right: -25px;
  }

  /*IE10*/
  .clip-right .clip-logo {
    transform: scale(0.5);
    left: -25px;
  }

  /*IE11*/
  *::-ms-backdrop,
  .clip-right .clip-logo {
    transform: scale(0.5);
    left: -25px;
  }
}
