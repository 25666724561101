// subscribe start

.subscribe {
	@extend .text-lg-center;
	@include py(95px);
	background-color: $primary;
	background-image: url(../img/subscribe-bg.jpg);
	background-position: center center;
	background-size: cover;
	@include max767 {
		padding-top: 70px;
		padding-bottom: 85px; }
	@include max575 {
		@include py(50px); }
	.title {
		color: $white; } }

// subscribe end

