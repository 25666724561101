/* buttons start */
.btn {
	@extend .d-inline-block;
	@include r(3px);
	@include font-main;
	@include fz(18);
	@include ttu;
	@include fw(700);
	line-height: 1.2;
	@include px(15px);
	@include py(14px);
	min-width: 140px;
	@include min768 {
		min-width: 265px; }
	@include max767 {
		@include py(16px); }
	@include h {
		cursor: pointer; } }

.header {
	.btn {
		@include fz(16);
		text-transform: none;
		@include robotocondenced;
		@include px(15px);
		@include min768 {
			@include px(10px); }
		@include min1200 {
			@include px(25px);
			min-width: 140px; }
		@include max1199 {
			min-width: 1px;
			display: inline-flex;
			flex-wrap: wrap; }
		@include max991 {
			margin-top: 20px;
			margin-bottom: 20px; } } }

@include btn(info, $white, $info);

.text-link {
	@extend .d-inline-block;
	color:  $text_link;
	@include tm;
	@include h {
		color:  $text_link; } }

a.text-link {
	@include tdu;
	@include h {
		color: $primary;
		cursor: pointer;
		@include tdu;
		@include tm; } }

button {
	display: inline-block;
	border: none;
	background-color: transparent;
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	-webkit-appearance: none;
	-webkit-writing-mode: flex !important;
	cursor: pointer;
	@include h {
		cursor: pointer; }
	@include fc {
		outline: none;
		box-shadow: none; } }

.btn-block {
	@include max767 {
		@include py(10px); } }

.btn-icon {
	display: inline-block;
	max-width: 22px; }

/* buttons end */



